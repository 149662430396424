import axios from "axios";
// import AxiosMockAdapter from "axios-mock-adapter";

// if (process.env['NODE_ENV'] !== 'production') {
//     // Create axios mock adapter
//     const mockAdapter = new AxiosMockAdapter(axios)

//     mockAdapter.onGet(/\/([A-Za-z0-9])/).reply(config => {
//         return [200, {
//             // Descrição do acordo/nome da carteira
//             var14: 'À vista com 50% de desconto por R$ 51,39',
//             // Linha digitável do código de barras
//             var11: '12371928379187381231',
//             // Data de vancimento
//             var13: '2021-05-31',
//             // Valor da parcela
//             var12: '50,00',
//             // Nome do cliente
//             var6: 'Luan Otávio João Baptista'
//         }]
//     })
// }

/**
 * Get API
 */
const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

/**
 * Create request to fetch customer name
 * @param {string} tokenProposal Token for proposal
 * @returns {AxiosPromise}
 */
export const fetchCustomerName = tokenProposal => api.get(`/proposals/${tokenProposal}/customer/name`)

/**
 * Create request to fetch proposal summary
 * @param {string} tokenProposal Token for proposal
 * @returns {AxiosPromise}
 */
export const fetchReminder = tokenProposal => api.get(`/${tokenProposal}`)

/**
 * Create request to fetch proposal summary
 * @param {string} tokenProposal Token for proposal
 * @returns {AxiosPromise}
 */
export const fetchSummary = tokenProposal => api.get(`/proposals/${tokenProposal}/summary`)

/**
 * Create request to validate customer document
 * @param {Object} data Values to validate customer document
 * @returns {AxiosPromise}
 */
export const validateCustomerDocument = data => api.post('/proposals/validate-customer', data)

/**
 * Create request to confirm proposal
 * @param {Object} data Values to confirm proposal
 * @returns {AxiosPromise}
 */
export const confirmProposal = data => api.post('/proposals/confirm', data)

/**
 * Create request to register user activities
 * @param {Object} data Values for user activity
 * @returns {AxiosPromise}
 */
export const storeActivity = (token, action) => api.post(`/${token}/${action}`)

export const activities = {
    'acess-the-page': 1,
    'create-reminder': 2,
    'copy-digitable-line': 3,
    'send-sms': 4
}

export default api;