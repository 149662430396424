import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Reminder from './pages/Reminder';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            {/* <Route exact path="/proposal" component={Proposals} />
            <Route exact path="/summary" component={ProposalSummary} />
            <Route exact path="/reminder/:token" component={Reminder} /> */}
            <Route exact path="/:token" component={Reminder} />
            {/* <PrivateRoute path="/app" component={() => <h1>App</h1>} /> */}
            <Redirect path="/" to={ { pathname: '/not-found' } } />
            {/* <Route path="*" component={() => <h1>Page not found</h1>} /> */}
        </Switch>
    </BrowserRouter>
);

export default Routes;