import { faBan } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React, { Component } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import FadeIn from "react-fade-in";
import MetaTags from 'react-meta-tags';
import { Redirect } from "react-router-dom";
import { getLogo } from '../../brandOptions';
import AddToCalendar from "../../components/AddToCalendar";
import ErrorAlert from "../../components/ErrorAlert";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import PageLoader from "../../components/PageLoader";
import { activities, fetchReminder, storeActivity } from "../../services/api";
import "./styles.scss";

class Reminder extends Component {
    state = {
        customer: {
            name: null
        },
        summary: {
            // Valor da dívida
            amount: null,
            // Número do código de barras
            barcode_number: null,
            // Descrição do acordo/nome da carteira
            description: null,
            // Linha digitável do código de barras
            digitable_barcode_number: null,
            // Data de vancimento
            due_date: null,
            // Valor da parcela
            installment_value: null,
            // Nome do cliente
            customer_name: null,
            
        },
        calendarEvent: {
            name: "Lembrete de pagamento do acordo",
            details: "",
            location: "",
            startsAt: "2018-12-06T17:00:00-05:00",
            endsAt: "2018-12-06T18:00:00-05:00"
        },
        error: {
            show: false,
            content: null
        },
        copied: false,
        redirect: null,
        loading: true,
        smsSent: false,
        sms: {
            loading: false,
            error: {
                show: false,
                content: null
            }
        }
    }

    constructor(props) {
        super(props)
        this.handleOpenCalendar = this.handleOpenCalendar.bind(this)
        this.handleSubmitSms = this.handleSubmitSms.bind(this)
    }

    getToken() {
        const { match: { params } } = this.props
        return params.token
    }

    async componentDidMount() {
        // Get proposal token from storage
        const token = this.getToken()

        if (token === 'not-found') {
            // Error state
            const error = { show: true, content: "Esta proposta é inválida" }
            
            // Set states for submit button and show the error
            return this.setState({ error, loading: false })
        }

        // // Store a user activity
        // storeActivity(token, activities['copy-digitable-line']).then()

        try {
            const { data } = await fetchReminder(token)

            const summary = {
                // Descrição do acordo/nome da carteira
                description: data.proposta,
                // // Linha digitável do código de barras
                // digitable_barcode_number: data.var11,
                // Data de vancimento
                due_date: data.vencimento,
                // // Valor da parcela
                // installment_value: data.var12
            }

            // Nome do cliente
            const customer = { name: data.var6 }

            // Create moment object from due date
            const dueDate = moment(summary.due_date, 'DD/MM/YYYY')
            dueDate.set({ hours: 8 })

            // Set a formatted due date
            summary.due_date = dueDate.format('DD/MM/YYYY')

            // Calendar event values
            const calendarEvent = {
                name: `Pagamento do acordo: ${summary.description}`,
                details: `Lembrete de vencimento do pagamento do acordo "${summary.description}"`,
                location: "",
                startsAt: dueDate.format('YYYY-MM-DDTHH:mm:ss'),
                endsAt: dueDate.add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss')
            };

            this.setState({ summary, customer, calendarEvent, loading: false, smsSent: data.sms_sent })
        } catch (err) {
            // Get error response status and data
            const { response: { status, data } } = err
            
            // Error state
            const error = { show: true, content: null }

            if (status === 404) {
                error.content = "Não há acordo firmado para esta proposta";
            } else if (status === 400) {
                error.content = "Esta proposta é inválida"
            } else {
                error.content = data.error || "Serviço temporáriamente indisponível"
            }

            // Set states for submit button and show the error
            this.setState({ error, loading: false })
        }
    }

    handleCopyBarcode = () => {
        // Store a user activity
        storeActivity(this.getToken(), activities['copy-digitable-line'])

        this.setState({ copied: true })
    }

    handleOpenCalendar() {
        // Store a user activity
        storeActivity(this.getToken(), activities['create-reminder'])
    }

    async handleSubmitSms() {
        if (this.state.smsSent) {
            return false
        }

        this.setState({
            sms: {
                loading: true,
                error: {
                    show: false,
                    content: null
                }
            }
        })

        try {
            await storeActivity(this.getToken(), activities['send-sms'])

            setTimeout(() => {
                this.setState({
                    smsSent: true,
                    sms: {
                        loading: false,
                        error: {
                            show: false,
                            content: null
                        }
                    }
                })
            }, 2000)
        } catch (err) {
            this.setState({
                smsSent: false,
                sms: {
                    loading: false,
                    error: {
                        show: true,
                        content: "Não foi possível enviar o SMS"
                    }
                }
            })
        }
    }

    renderRedirect = () => {
        if (this.state.redirect !== null)
            return (<Redirect to={this.state.redirect} />)
        else
            return (<></>)
    }

    render = () => {
        if (this.state.redirect !== null)
            return (<Redirect to={this.state.redirect} />)

        if (this.state.loading)
            return <PageLoader />

        return (
            <>
                <MetaTags>
                    <title>Portal de negociação</title>
                    {this.state.error.show === false ? (<>
                        <meta name="description" content={`Lembrete de pagamento do seu acordo com vencimento em ${this.state.summary.due_date}`} />
                        
                        <meta property="og:site_name" content="Portal de negociação"></meta>
                        <meta property="og:description" content={`Lembrete de pagamento do seu acordo com vencimento em ${this.state.summary.due_date}`} />
                        <meta property="og:title" content="Portal de negociação" />
                        <meta property="og:image" content={getLogo(window.location.hostname, 'v')} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`/${this.getToken()}`} />
                    </>) : (<></>)}
                </MetaTags>
                <FadeIn>
                    <Header />
                    <Card className="shadow-sm border-0">
                        <Container className="py-3">
                            {this.state.error.show
                            ? (<ErrorAlert {...this.state.error} icon={faBan} />)
                            : (<>
                                <Row className="pb-3">
                                    <Col>
                                        <Card.Title>Lembrete do seu acordo</Card.Title>
                                        <Card.Subtitle className="small text-muted">Abaixo as informações da negociação</Card.Subtitle>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form">
                                            <div className="form-group mb-2">
                                                <div className="small">Proposta</div>
                                                <div>{this.state.summary.description}</div>
                                            </div>
                                            <div className="form-group mb-2">
                                                <div className="small">Vencimento</div>
                                                <div>{this.state.summary.due_date}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {this.state.sms.error.show ? <ErrorAlert {...this.state.sms.error} icon={faBan} /> : (<></>)}
                                
                                <Row>
                                    <Col className="mt-2">
                                        {/* <Button block variant="primary-bsco" onCopy={this.handleCopyBarcode} text={this.state.summary.digitable_barcode_number} as={CopyToClipboard}>
                                            <span>{!this.state.copied ? "Copiar linha digitável" : "Copiado!"}</span>
                                        </Button> */}
                                        <div className="form">
                                            <div className="form-group mt-2 mb-3">
                                                {/* <div className="small">Vencimento</div> */}
                                                <div className="small text-center">Como gostaria de ser lembrado<br/>do vencimento do seu acordo?</div>
                                            </div>
                                        </div>

                                        <Button block variant="primary-bsco" onClick={this.handleSubmitSms} disabled={this.state.smsSent || this.state.sms.loading}>
                                            <span className={this.state.smsSent ? "small" : ""}>
                                                Lembrete automático via SMS{this.state.smsSent ? " agendado" : ""}
                                            </span>
                                            <Loader show={this.state.sms.loading} />
                                        </Button>

                                        <AddToCalendar className="mt-2" event={this.state.calendarEvent}
                                            placeholder="Lembrete automático no celular"
                                            variant="primary-bsco"
                                            onOpen={this.handleOpenCalendar}
                                            calendars={['apple', 'google', 'outlook', 'outlook.web', 'yahoo']} />
                                    </Col>
                                </Row>
                            </>)}
                        </Container>
                    </Card>
                </FadeIn>
            </>
        )
    }
}

export default Reminder