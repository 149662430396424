import hClaroMtexApp from "./assets/img/horizontal/claro.mtex.app.png"
// import hDefault from "./assets/img/horizontal/default.png"
import hRecMtexApp from "./assets/img/horizontal/rec.mtex.app.png"
import hBradescoMtexApp from "./assets/img/horizontal/bradesco.mtex.app.jpeg"
import vClaroMtexApp from "./assets/img/vertical/claro.mtex.app.png"
// import vDefault from "./assets/img/vertical/default.png"
import vRecMtexApp from "./assets/img/vertical/rec.mtex.app.png"
import vBradescoMtexApp from "./assets/img/vertical/bradesco.mtex.app.jpeg"

export const logo = {
    vertical: {
        'claro.mtex.app': vClaroMtexApp,
        // 'default': vDefault,
        'default': vBradescoMtexApp,
        'rec.mtex.app': vRecMtexApp,
        'bradesco.mtex.app': vBradescoMtexApp
    },

    horizontal: {
        'claro.mtex.app': hClaroMtexApp,
        // 'default': hDefault,
        'default': hBradescoMtexApp,
        'rec.mtex.app': hRecMtexApp,
        'bradesco.mtex.app': hBradescoMtexApp
    }
}

export const headerTitle = {
    'claro.mtex.app': 'Lembrete de pagamento',
    'default': 'Lembrete de pagamento',
    // 'default': 'Portal de negociação',
    'rec.mtex.app': 'Lembrete de pagamento',
    'bradesco.mtex.app': 'Portal de negociação'
}

export const headerOptions = {
    'claro.mtex.app': {
        title: 'Lembrete de pagamento',
        direction: 'horizontal'
    },
    'default': {
        title: 'Portal de Negociação',
        direction: 'vertical'
    },
    'rec.mtex.app': {
        title: 'Lembrete de pagamento',
        direction: 'horizontal'
    },
    'bradesco.mtex.app': {
        title: 'Portal de Negociação',
        direction: 'vertical'
    },
}

export const getLogo = (domain, orientation) => {
    if (orientation === 'h')
        return logo.horizontal[domain] ?? logo.horizontal.default

    return logo.vertical[domain] ?? logo.vertical.default
}

export const getHeaderOptions = (domain) => {
    return headerOptions[domain] ?? headerOptions.default
}